// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';
import { Upload } from 'src/components/upload';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/panel',
  PROFILE: '/profile',
};

// ----------------------------------------------------------------------

export const paths = {
  bayi: '/',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',

  //PROFILE
  account: {
    root: ROOTS.PROFILE,
    profile: `${ROOTS.PROFILE}/order/list`,
    payment: {
      list: `${ROOTS.PROFILE}/payment`,
      new: `${ROOTS.PROFILE}/payment/new`,
      details: (id: string) => `${ROOTS.PROFILE}/payment/${id}`,
    },
    order: {
      list: `${ROOTS.PROFILE}/order/list`,
      details: (id: string) => `${ROOTS.PROFILE}/order/${id}`,
      edit: (id: string) => `${ROOTS.PROFILE}/order/${id}/edit`,
      pending: `${ROOTS.PROFILE}/order/pending`,
    },
    statement: {
      list: `${ROOTS.PROFILE}/statement/list`,
    },
    category: {
      list: `/categories`,
    },
  },

  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (slug: string) => `/product/${slug}`,
    query: (queryData: string) => `/product/list/${queryData}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      verify: `${ROOTS.AUTH}/jwt/verify`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgot-password`,
      newPassword: `${ROOTS.AUTH}/jwt/new-password`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    import: `${ROOTS.DASHBOARD}/import`,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    comingSoon: `${ROOTS.DASHBOARD}/coming-soon`,
    fileManager: {
      root: `${ROOTS.DASHBOARD}/file-manager`,
      list: `${ROOTS.DASHBOARD}/file-manager/list`,
      details: (id: string) => `${ROOTS.DASHBOARD}/file-manager/${id}`,
      subFolder: (id: string, folder: string, subfolder: string) =>
        `${ROOTS.DASHBOARD}/file-manager/${id}/sub-folder/${folder}/${subfolder}`,
    },
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      summary: `${ROOTS.DASHBOARD}/user/summary`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      address: `${ROOTS.DASHBOARD}/user/address`,
      role: `${ROOTS.DASHBOARD}/user/role`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      details: (id: string) => `${ROOTS.DASHBOARD}/user/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      upload  :`${ROOTS.DASHBOARD}/user/upload`,
    },
    company: {
      root: `${ROOTS.DASHBOARD}/company`,
      summary: `${ROOTS.DASHBOARD}/company/summary`,
      list: `${ROOTS.DASHBOARD}/company/list`,
      address: `${ROOTS.DASHBOARD}/company/address`,
      account: `${ROOTS.DASHBOARD}/company/account`,
      details: (id: string) => `${ROOTS.DASHBOARD}/company/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/company/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/company/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/company/${MOCK_ID}/edit`,
      },
    },
    bufipay: {
      root: `${ROOTS.DASHBOARD}/bufipay`,
      summary: `${ROOTS.DASHBOARD}/bufipay/summary`,
      list: `${ROOTS.DASHBOARD}/bufipay/list`,
      cancel: `${ROOTS.DASHBOARD}/bufipay/cancel`,
      refund: `${ROOTS.DASHBOARD}/bufipay/refund`,
      link: `${ROOTS.DASHBOARD}/bufipay/link`,
      report: `${ROOTS.DASHBOARD}/bufipay/report`,
      settings: `${ROOTS.DASHBOARD}/bufipay/settings`,
      pos: `${ROOTS.DASHBOARD}/bufipay/pos`,
      bank: `${ROOTS.DASHBOARD}/bufipay/bank`,
      api: `${ROOTS.DASHBOARD}/bufipay/api`,
      binnumber: `${ROOTS.DASHBOARD}/bufipay/binnumber`,
      new: `${ROOTS.DASHBOARD}/bufipay/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/bufipay/${id}`,
      installment: (id: string) => `${ROOTS.DASHBOARD}/bufipay/${id}/installment`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/bufipay/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/bufipay/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/bufipay/${MOCK_ID}/edit`,
        installment: `${ROOTS.DASHBOARD}/bufipay/${MOCK_ID}/installment`,
      },
      payment: {
        list: `${ROOTS.DASHBOARD}/bufipay/payment`,
        new: `${ROOTS.DASHBOARD}/bufipay/payment/new`,
        details: (id: string) => `${ROOTS.DASHBOARD}/bufipay/payment/${id}`,
        demo: {
          details: `${ROOTS.DASHBOARD}/bufipay/payment/${MOCK_ID}`,
        },
      },
    },
    product: {
      list: `${ROOTS.DASHBOARD}/product/list`,
      root: `${ROOTS.DASHBOARD}/product`,
      category: `${ROOTS.DASHBOARD}/product/category`,
      brand: `${ROOTS.DASHBOARD}/product/brand`,
      type: `${ROOTS.DASHBOARD}/product/type`,
      feature: `${ROOTS.DASHBOARD}/product/feature`,
      price: `${ROOTS.DASHBOARD}/product/price`,
      stock: `${ROOTS.DASHBOARD}/product/stock`,
      campaign: `${ROOTS.DASHBOARD}/product/campaign`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
      slider: {
        list: `${ROOTS.DASHBOARD}/product/slider`,
        new: `${ROOTS.DASHBOARD}/product/slider/new`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product/slider/${id}/edit`,
        demo: {
          edit: `${ROOTS.DASHBOARD}/product/slider/${MOCK_ID}/edit`,
        },
      },
      collection: {
        list: `${ROOTS.DASHBOARD}/product/collection`,
        new: `${ROOTS.DASHBOARD}/product/collection/new`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/product/collection/${id}/edit`,
        demo: {
          edit: `${ROOTS.DASHBOARD}/product/collection/${MOCK_ID}/edit`,
        },
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      list: `${ROOTS.DASHBOARD}/order/list`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/order/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
