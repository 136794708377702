import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// hooks
// components
import MenuItem from '@mui/material/MenuItem';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from '../config-navigation';
import { NavToggleButton } from '../../../_common';
import { alpha, useTheme } from '@mui/system';
import { AnimateAvatar } from 'src/components/animate';
import { useAuthContext } from 'src/auth/hooks';
import {
  Alert,
  Badge,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { SignOutButton } from 'src/components/sign-out-button';
import { useSnackbar } from 'src/components/snackbar';
import { usePopover } from 'src/components/custom-popover';
import { useRouter } from 'src/routes/hooks';
import { useOptions } from 'src/store/slices/options/hooks.slice';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import { useCheckout } from 'src/store/slices/checkout/checkout.hooks';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const pathname = usePathname();
  const router = useRouter();
  const lgUp = useResponsive('up', 'lg');
  const theme = useTheme();
  const navData = useNavData().map((section: { items: any[], subheader?: string }) => ({
    ...section,
    subheader: section.subheader || '',
  }));
  const popover = usePopover();
  const { enqueueSnackbar } = useSnackbar();
  const { logout, user, selectedCompany } = useAuthContext();
  const options = useOptions();
  const { totalItems } = useCheckout();
  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/auth/jwt/login?returnTo=%2F');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleCompanyChange = () => {
    popover.onClose();
    options.setOptions({ open: true });
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack direction="row" sx={{ ml: 3, pt: 2 }}>
        <AnimateAvatar
          width={50}
          slotProps={{
            avatar: { src: user?.image ?? '/assets/images/image.png', alt: `${user?.first_name} ${user?.last_name}` },
            overlay: {
              border: 2,
              spacing: 3,
              color: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 25%, ${
                theme.palette.primary.main
              } 100%)`,
            },
          }}
        >
          {user?.displayName?.charAt(0).toUpperCase()}
        </AnimateAvatar>

        <Stack sx={{ ml: 2 }} justifyContent="flex-start" alignItems="left">
          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
            {user?.first_name} {user?.last_name}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            {user?.Role?.name}
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <MenuItem>
        <Alert
          severity="info"
          sx={{ width: '100%' }}
          action={
            (user?.Role.type == 'admin' ||
              user?.Role.type == 'seller' ||
              user?.Company.length > 1) && (
              <Button color="inherit" size="small" onClick={handleCompanyChange}>
                Değiştir
              </Button>
            )
          }
        >
          {selectedCompany ? selectedCompany?.name?.slice(0, 20) : user?.Company[0]?.name}
        </Alert>
      </MenuItem>
      <Stack sx={{ p: 1 }} spacing={2} direction={"row"} >
        <>
      {user?.Role.type === 'admin' && (
        <Tooltip title="Admin Panel">
        <IconButton href={paths.dashboard.root}>
          <Iconify icon="solar:shield-user-bold" />
        </IconButton>
        </Tooltip>
      )}
       <Tooltip title="Anasayfa">
        <IconButton href={'/'}>
          <Iconify icon="solar:home-smile-angle-bold" />
        </IconButton>
        </Tooltip>

        <Tooltip title="Koleksiyonlar">
        <IconButton href={paths.account.category.list}>
          <Iconify icon="solar:widget-bold" />
        </IconButton>
        </Tooltip>
        <Tooltip title="Sepet">
            <IconButton
              component={Link}
              to="/cart"
              sx={{
                color: 'text.primary',
                '&:hover': { bgcolor: 'transparent' },
                mr: 1.5,
              }}
            >
              <Badge badgeContent={totalItems} color="primary">
                <Iconify icon="solar:cart-3-bold" width={24} />
              </Badge>
            </IconButton>
          </Tooltip>
        </>
      </Stack>
    
      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ p: 2.5 }}>
            <SignOutButton onClick={handleLogout} />
          </Box>
    </Scrollbar>
    
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
          
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
          
        </Drawer>
      )}
    </Box>
  );
}
